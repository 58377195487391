'use client';
import { EventsCarouselSkeleton } from '@v2/components/EventsCarouselSkeleton/EventsCarouselSkeleton';
import { AWSLocationResponse } from '@v2/types';
import { getCityPageData } from '@v2/views/city/services/getCityPageData';
import { useSession } from 'next-auth/react';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { EventLocationDropdown } from './EventLocationDropdown';
import { EventsCarousel } from './EventsCarousel';

interface Props {
  initialLocationEvents?: any;
}

export const EventsCarouselByLocation = ({ initialLocationEvents }: Props) => {
  console.log(initialLocationEvents);

  const [locationCityName, setLocationCityName] = useState<string>();
  const [selectedLocation, setSelectedLocation] =
    useState<AWSLocationResponse>();
  const [loading, setLoading] = useState(false);
  const serverEvents = useMemo(() => initialLocationEvents, []);

  const { data } = useSession();
  const defaultUserLocation = data?.user?.location;

  const handleGetLocation = async (loc: string) => {
    const cityData = await getCityPageData(loc);

    if (cityData) {
      const item = { ...cityData, Text: cityData.Municipality };

      setSelectedLocation(item);
      setLocationCityName(`${cityData.Municipality},`);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (defaultUserLocation) {
      setLoading(true);
      handleGetLocation(defaultUserLocation);
    }
  }, [defaultUserLocation]);

  const onLocationChange = useCallback((location: AWSLocationResponse) => {
    setLocationCityName(`${location.Text} ${location?.Region ?? ''}`);
    setSelectedLocation(location);
  }, []);

  if (loading) return <EventsCarouselSkeleton withTitle />;
  return (
    <div className="flex flex-col">
      <EventLocationDropdown
        selectedLocation={selectedLocation}
        onLocationChange={onLocationChange}
      />

      <Suspense fallback={<EventsCarouselSkeleton withTitle={false} />}>
        <EventsCarousel
          initialEvents={serverEvents}
          location={locationCityName}
        />
      </Suspense>
    </div>
  );
};
