import routes from '@config/routes';
import { serverFetcher } from '@instance';
import { getRandomNumber } from '@v2/utils/random-number';
import { Props, addQuery, imagePath } from '../getEventsByLocation';
import { formatLeagueSlug, getVenueImages } from '../getVenueImages';

export const getEventsByLocation = async ({
  lat,
  lon,
  page = 1,
  perPage = 50,
  concerts = false,
  withVenueImages = false,
}: Props) => {
  const eventsList = await serverFetcher(
    `${routes.eventLocation}?${addQuery({ page, perPage, lat, lon, concerts })}`,
    {
      method: 'GET',
    }
  );

  if (!withVenueImages) {
    return eventsList;
  }

  if (concerts) {
    const formattedEvents = eventsList.map((event) => {
      const randomNumber = getRandomNumber();

      const image = `${imagePath}/rsc-${randomNumber}.jpg`;
      return {
        ...event,
        venue: { ...event?.venue, image },
      };
    });

    return { events: formattedEvents, total: eventsList.length };
  }

  const venueImages = await getVenueImages(eventsList);

  const formattedEvents = eventsList.map((event) => {
    const venueImage = venueImages.find(
      (item) =>
        item.venueId === event?.venue?.id &&
        item.league === formatLeagueSlug(event?.category?.slug)
    );
    return {
      ...event,
      venue: { ...event?.venue, image: venueImage?.imagePath },
    };
  });

  return formattedEvents;
};
